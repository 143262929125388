import { Validators } from '@angular/forms';
import { NgxLoggerLevel } from 'ngx-logger';
import {
  BaseColumn,
  ControlName,
  Pagination,
  PrgMissingTranslationHandler,
  TableConfigs,
} from '@prg/prg-core-lib';
import packageJson from '../../package.json';

const DEFAULT_CACHE_TIME = 3600;

export const commonEnv = {
  version: packageJson.version,
  logLevel: NgxLoggerLevel.TRACE,
  serverLogLevel: NgxLoggerLevel.OFF,
  production: false,
  useMockServices: true,
  useDefaultToken: true,
  coreModuleConfigurations: {
    baseUrl: 'https://localhost:7226',
    urlApiPath: '/api',
    authApiPaths: {
      login: 'Authentication',
      tokenValidation: 'Authentication',
      register: 'Authentication/Register',
      simpleRegister: 'Authentication/SimpleRegister',
      confirmationAccount: 'Authentication/ConfirmationAccount',
      emailConfirmation: 'Authentication/EmailConfirmation',
      requestPassword: 'Authentication/RequestPasswordReset',
      resetPassword: 'Authentication/ResetPassword',
      workspaceToken: 'Authentication/getworkspacetoken',
      refreshToken: null,
      logout: null,
    },
    queryApiPaths: {
      create: 'Query',
      update: 'Query',
      getAll: 'Query/list',
      getById: 'Query/ById',
      executeQuery: 'Query/ExecuteQuery',
      delete: 'Query',
    },
    lookupTableApiPaths: {
      create: 'LookupTable',
      getAll: 'LookupTable/filtered',
      getLookupTableByIdWithItems: 'LookupTable/LookupTableByIdWithItems',
      items: {
        add: 'LookupTable/AddLookupItem',
      },
    },
    userManagementApiPaths: {
      getUserById: 'User/ByIdWithRolesScopes',
      getUsersByRole: 'User/ByRole',
      createUser: 'User',
      updateUser: 'User/UpdateRolesAndScopes',
      getAllRoles: 'Role/WithScopes',
      createRole: 'Role',
      updateRole: 'Role',
    },
    entityTypeApiPaths: {
      getAll: 'EntityType',
      createEntityType: 'EntityType',
      updateEntityTypePut: 'EntityType',
      getAllEntityTypeWithOperation: 'EntityType/EntityTypeListWithOperation',
      entityTypeByName: 'EntityType/ByName',
      properties: {
        createProperty: 'EntityTypeProperty',
        updatePropertyPut: 'EntityTypeProperty',
        getAllByEntityTypeId: 'EntityTypeProperty/AllPropertiesByEntityTypeId',
        updateOrder: 'EntityTypeProperty/UpdateEntityTypePropertiesOrder',
      },
      attributes: {
        createAttribute: 'EntityTypeAttribute',
        updateAttributePut: 'EntityTypeAttribute',
        getAllByEntityTypeId: 'EntityTypeAttribute/AllAttributesByEntityTypeId',
        updateOrder: 'EntityTypeAttribute/UpdateEntityTypeAttributesORder',
      },
      operations: {
        createOperation: 'EntityTypeOperation',
        updateOperationPut: 'EntityTypeOperation',
        getAllByEntityTypeId: 'EntityTypeOperation/AllOperationsByEntityTypeId',
      },
    },
    dashboardApiPaths: {
      create: 'dashboard',
      getById: 'dashboard/ById',
      getByName: 'dashboard/ByName',
      updateDashboard: 'dashboard/UpdateDashboard',
      getByUserId: 'dashboard/ByUserId',
    },

    configurationItemsApiPaths: {
      getAll: 'ConfigurationItem/list',
      updateMultipleDefaults: 'ConfigurationItem/UpdateDefaults/Multiple',
      updateMultiple: 'ConfigurationItem/Update/Multiple',
      getBypath: 'ConfigurationItem/ByPath',
    },
    stateModelApiPaths: {
      create: 'statemodel',
      update: 'statemodel',
      getAll: 'statemodel',
      delete: 'statemodel',
      getById: 'statemodel/byid',
      getByName: 'statemodel/byname',
      saveStateModelState: 'statemodel/savestatemodelstate',
      stateModelStatesOptions: 'statemodel/stateModelStatesOptions',
    },
    dynamicForm: {
      formValueChangeDebounce: 500,
      inputValueChangeDebounce: 500,
    },
  },
  documentsModuleConfigurations: {
    documentsBasePath: 'documents',
    useDefaultRouting: false,
    cacheTime: DEFAULT_CACHE_TIME,
  },
  rolesScopesModuleConfigurations: {
    useMockServices: true,
    rolesScopesBasePath: 'roles-scopes',
    baseScopes: ['view', 'create', 'update', 'delete'],
    otherScopes: 'other',
  },
  authModuleConfigurations: {
    useMockServices: false,
    authRoutingBasePath: 'auth',
    authRoutingSignupPath: 'signup',
    authRoutingLoginPath: 'login',
    authRoutingRequestPasswordPath: 'request-password',
    authRoutingResetPasswordPath: 'reset-password',
    authRoutingRgpdPath: 'rgpd',
    authRoutingLoginOkRedirectPath: 'work-order',
    authRoutingLogoutPath: 'logout',
    termsOfUseRoutingPath: 'terms-of-use',
    authStorage: {
      storage: window.localStorage,
      keyStoredUserName: 'savedUserEmail',
      keyLoggedUser: 'loggedUser',
      keyRgpdAcceptance: 'techpartsRgpdAccepted',
    },
    useDefaultRouting: true,
    cacheTime: DEFAULT_CACHE_TIME,
    pollNotificationsTime: 60000,
    pollNotificationsPageSize: 0,
    rgpdAcceptanceRequired: false,
    rgpdTranslationKey: 'components.terms-conditions.sections.terms1',
  },

  termsModuleConfigurations: {
    useMockServices: true,
    baseTermsTranslationKey: 'components.terms-conditions',
    termsOfUseRoutingPath: 'terms-of-use',
    useDefaultRouting: true,
    cacheTime: DEFAULT_CACHE_TIME,
  },

  dashboardModuleConfigurations: {
    useMockServices: false,
    dashboardRoutingBasePath: 'dashboard',
    dashboardConfigRoutingPath: 'config',
    dashboardDisplayRoutingPath: 'display',
    displayDashboard: 'view',
    useDefaultRouting: true,
    cacheTime: DEFAULT_CACHE_TIME,
  },

  configurationItemsModuleConfigurations: {
    useMockServices: false,
    configurationItemsRoutingBasePath: 'config-items',
    configurationItemsRoutingAdminPath: '',
    configurationItemsRoutingUserPath: 'user-preferences',
    configurationItemsRoutingNotificationsPath: 'notification-types',
    useDefaultRouting: true,
    cacheTime: DEFAULT_CACHE_TIME,
  },

  dynamicFormsConfigurations: {
    useMockServices: true,
    dynamicFormsBasePath: 'dynamic-forms',
  },

  entityTypeConfigurations: {
    useMockServices: false,
    entityTypeBasePath: 'entity-types',
    lookupTableDataTypeItemId: '11',
    entityTypeDataTypeItemId: '12',
    useDefaultRouting: true,
    entityTypeTableColumns: [
      new BaseColumn({
        value: null,
        readonly: true,
        key: 'name',
        validators: [],
        basePathTranslation: 'entities',
      }),
      new BaseColumn({
        value: null,
        readonly: true,
        key: 'guiSettings',
        validators: [],
      }),
      new BaseColumn({
        value: null,
        readonly: true,
        key: 'universalState',
        validators: [],
      }),
    ],

    specificEntityTypeListConfigs: new TableConfigs({
      defaultColumns: ['id', 'name', 'stock', 'expiryDate'],
      globalFilterFields: ['name', 'description'],
      pagination: new Pagination(),
      multiSortMeta: [
        { field: 'name', order: -1 },
        { field: 'stock', order: -1 },
      ],
    }),
    cacheTime: 10800,
  },
  lookupTableConfigurations: {
    useMockServices: false,
    lookupTableBasePath: 'lookup-table',
    useDefaultRouting: true,
    lookupTableColumns: [
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'name',
        validators: [Validators.required],
        readonly: true,
      }),
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'description',
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      }),
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'universalState',
        validators: [Validators.required],
      }),
    ],
    lookupTableItemsColumns: [
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'name',
        validators: [Validators.required],
        readonly: true,
      }),
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'description',
        validators: [Validators.required],
      }),
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'universalState',
        validators: null,
      }),
    ],
    cacheTime: DEFAULT_CACHE_TIME,
  },
  queriesModuleConfigurations: {
    useMockServices: false,
    queriesRoutingPath: 'queries',
    useDefaultRouting: true,
    queryTableColumns: [
      new BaseColumn({
        key: 'name',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
      new BaseColumn({
        key: 'description',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
      new BaseColumn({
        key: 'createdByName',
        readonly: true,
        basePathTranslation: 'queries.fields',
      }),
    ],
    cacheTime: DEFAULT_CACHE_TIME,
  },
  stateModelsConfigurations: {
    useMockServices: false,
    stateModelsBasePath: 'state-models',
    useDefaultRouting: true,
    stateModelsTableColumns: [
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'name',
        validators: [Validators.required],
        readonly: true,
      }),
      new BaseColumn({
        controlName: ControlName.inputText,
        key: 'universalState',
        validators: [Validators.required],
        readonly: true,
      }),
    ],
    cacheTime: DEFAULT_CACHE_TIME,
  },

  userManagementModuleConfigurations: {
    useMockServices: true,
    userManagementBasePath: 'user-management',
    useDefaultRouting: true,
    baseScopes: ['view', 'create', 'update', 'delete'],
    otherScopes: 'other',
    cacheTime: DEFAULT_CACHE_TIME,
  },

  workOrderModuleConfigurations: {
    useMockServices: false,
  },

  parkModuleConfigurations: {
    useMockServices: false,
  },
  checklistModuleConfigurations: {
    useMockServices: false,
  },
  dynamicForm: {
    formValueChangeDebounce: 500,
    inputValueChangeDebounce: 500,
  },
  translations: {
    defaultMissingTranslateClassHandler: PrgMissingTranslationHandler,
  },
};
